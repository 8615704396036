<template lang="pug">
div
  v-btn(@click="updateShowing(true)" block).mt-3 Confirmed courses
  app-dialog(v-model="showing" title="Confirmed courses")
    template(#dialog-body)
      .confirmed-courses-modal
        span(v-if="!courses.length") No data available
        course-item(v-for="course in courses" :item="course" :key="course.id")
          template(v-slot:datename) {{ dateName(course._date, course.date, course.name) }}
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import { formatingToDDD, getDOWDutchDDD } from '@/util'
export default {
  props: {
    courses: Array,
  },
  mixins: [showingMixin],
  components: {
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appBtn: () => import('@/components/global/actions/BaseButton.vue'),
    courseItem: () => import('@/app/admin/modules/candidates/components/candidates/CandidateCourseConfirmedItem.vue'),
  },

  methods: {
    dateName(_date, date, name) {
      if (name && date) return `${getDOWDutchDDD(new Date(_date).getDay())} ${date} - ${name}`
      else if(name) return `${name}`
      return `${formatingToDDD(date)} ${date}`
    }
  }

}
</script>

<style lang="scss">
.confirmed-courses-modal {
  span {
    display: block;
    font-size: 15px;
    padding: 3px 15px;
    border-bottom: 1px solid $border-color;
  }
}
</style>
